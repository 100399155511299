<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('predictions.items')" />

      <main-table
        :fields="fields"
        :params="filter"
        :list_url="'admin/prediction'"
        :reloadData="reloadData"
        :createStatus="false"
        :excelFields="excelFields"
        excelName="predictions"
      >
        <template #filter>
          <div class="filter-wrapper">
            <b-dropdown variant="outline-primary" right>
              <template #button-content>
                <i class="fas fa-filter"></i>
              </template>

              <div class="filter-item">
                <span>Created At</span>
                <input type="date" v-model="filter.from" class="form-control" />
              </div>

              <div class="filter-item">
                <span>To</span>
                <input type="date" v-model="filter.to" class="form-control" />
              </div>

              <div class="filter-item">
                <span>Status</span>
                <b-form-select
                  v-model="filter.status"
                  :options="statuses"
                  value-field="value"
                  text-field="name"
                >
                </b-form-select>
              </div>

              <div class="filter-item">
                <span>Race</span>
                <b-form-select
                  v-model="filter.race_id"
                  :options="raceList"
                  value-field="id"
                  text-field="nameForAdmin"
                >
                </b-form-select>
              </div>
              <div class="filter-item">
                <span>Competition</span>
                <b-form-select
                  v-model="filter.competition_id"
                  :options="competitionList"
                  value-field="id"
                  text-field="nameForAdmin"
                >
                </b-form-select>
              </div>

              <div class="filter-item">
                <span>Amount</span>
                <input
                  type="number"
                  v-model="filter.amount"
                  class="form-control"
                />
              </div>

              <!-- button to submit -->
              <div class="filter-item d-flex justify-content-end">
                <b-button
                  variant="primary"
                  @click="filterData"
                  class="rounded-sm px-4"
                  >Filter</b-button
                >
              </div>
            </b-dropdown>
          </div>
        </template>
      </main-table>
    </b-card>
  </div>
</template>
<script>
import searchMixin from "../../../../Mixins/searchMixin";
import api from "@/axios";
import { core } from "../../../../config/pluginInit";
export default {
  mixins: [searchMixin],
  data() {
    return {
      reloadData: false,
      raceList: [],
      competitionList: [],
      statuses: [
        {
          name: "All",
          value: null,
        },
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Win",
          value: "win",
        },
        {
          name: "Lost",
          value: "lost",
        },
      ],
      filter: {
        race_id: null,
        competition_id: null,
        from: null,
        to: null,
        status: null,
        amount: null,
      },
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        { key: "amount", label: this.$t("global.amount"), sortable: true },
        { key: "status", label: this.$t("global.status"), sortable: true },
        { key: "phone", label: this.$t("global.phone"), sortable: true },

        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "events.predictionView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("predictions.item"),
              textContent: "name",
              url: "admin/prediction",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("global.amount")]: "amount",
        [this.$t("global.result")]: "result",
        [this.$t("clients.item")]: "user.name",
        [this.$t("global.phone")]: "user.phone",
        [this.$t("races.item")]: "participant.raceName",
        [this.$t("participants.item")]: "participant.number",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },

  methods: {
    async getRaceList() {
      const { data } = await api().get("admin/race/names");
      this.raceList = [
        {
          nameForAdmin: "All",
          id: null,
        },
        ...data.data,
      ];
    },
    async getCompetitionList() {
      const { data } = await api().get("admin/competition/names");
      this.competitionList = [
        {
          nameForAdmin: "All",
          id: null,
        },
        ...data.data,
      ];
    },
    filterData() {
      this.reloadData = true;
      setTimeout(() => {
        this.reloadData = false;
      }, 0);
    },
  },
  mounted() {
    core.index();
    this.getRaceList();
    this.getCompetitionList();
  },
};
</script>

<style lang="scss">
.filter-wrapper {
  ul {
    min-width: 256px;
    max-height: 300px;
    overflow-y: auto;
  }
  .filter-item {
    margin-bottom: 0.5rem;
    span {
      display: block;
      font-size: 12px;
      font-weight: bold;
      color: #7e7e7e;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
    }
    input {
      &.form-control {
        height: calc(1.5em + 0.75rem + 2px);
        border-radius: 0.25rem;
      }
      &[type="number"] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    select {
      font-size: 0.875rem;
      margin-bottom: 0px !important;
    }
  }
}
</style>
